<template>
  <div>
    <div :id="props.id" :class="props.className" />
  </div>
</template>

<script setup>
import * as echarts from 'echarts/core'
import { GridComponent, VisualMapComponent, TooltipComponent } from 'echarts/components'
import { LineChart } from 'echarts/charts'
import { UniversalTransition } from 'echarts/features'
import { CanvasRenderer } from 'echarts/renderers'
import dayjs from 'dayjs'

const props = defineProps({
  data: {
    type: Array,
    default: () => []
  },
  direction: {
    type: Number,
    default: 0
  },
  xKey: {
    type: [String, Number],
    default: 'calculateTime'
  },
  yKey: {
    type: [String, Number],
    default: 'marketIndex'
  },
  id: {
    type: String,
    default: 'echart-dom'
  },
  className: {
    type: String,
    default: ''
  },
  lastDayEnd: {
    type: Boolean,
    default: false
  }
})

let data = ref(JSON.parse(JSON.stringify(props.data)))
data.value.array = []
data.value.yesterdayarray = []
let redStart = 0
let redstartTS = 0
let yesterdayLastData = ref(0)
onMounted(() => {
  var today = new Date()

  // 设置时间为00:00:00
  today.setHours(0, 0, 0, 0)

  // 获取时间戳（毫秒）
  var todaytimestamp = today.getTime()
  let maxTS = 0

  if (props.lastDayEnd) {
    for (let i = 0; i < data.value.length; i++) {
      const dataTS = dayjs(data.value[i][props.xKey] * 1000).valueOf()
      if (dataTS < todaytimestamp) {
        if (dataTS > maxTS) {
          maxTS = dataTS

          yesterdayLastData.value = data.value[i][props.yKey]
        }
      }
    }
  }

  for (let i = 0; i < data.value.length; i++) {
    if (yesterdayLastData.value) {
      data.value[i][props.yKey] = data.value[i][props.yKey] - yesterdayLastData.value
    }
    if (dayjs(data.value[i][props.xKey] * 1000).valueOf() > todaytimestamp && !redStart) {
      redStart = i
      redstartTS = data.value[i][props.xKey]
    }
    if (!redStart) {
      data.value.yesterdayarray.push([data.value[i][props.xKey] * 1, data.value[i][props.yKey]])
    } else {
      data.value.array.push([data.value[i][props.xKey] * 1, data.value[i][props.yKey]])
    }
  }
  echartInit()
})
function echartInit() {
  echarts.use([GridComponent, VisualMapComponent, LineChart, CanvasRenderer, UniversalTransition, TooltipComponent])

  var chartDom = document.getElementById(props.id)
  var myChart
  if (chartDom) {
    myChart = echarts.init(chartDom)
    var option
    var color = props.direction > 0 ? '255, 50, 51' : '61, 202, 100'
    data.value.array.unshift(data.value.yesterdayarray[data.value.yesterdayarray.length - 1])
    option = {
      grid: {
        y: 'datamin',
        height: '98%',
        left: 0,
        right: '2px',
        containLabel: false
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        show: false
        // data: [data.value.yesterdayarray.map(item => item[0]), data.value.array.map(item => item[0])]
      },
      tooltip: {
        appendToBody: true,
        trigger: 'axis',
        formatter: function (params) {
          var res = ''
          const value = params[0].value[1] + yesterdayLastData.value
          res =
            '<span style="color: #F87600;font-size: 16px">' +
            value.toFixed(2) +
            '</span>' +
            '<br/>' +
            '<span style="color: #979CAE">' +
            dayjs(params[0].value[0] * 1000).format('YYYY-MM-DD HH:mm') +
            '</span>' +
            '<br/>'
          // for (var i = 0; i < params.length; i++) {}
          return res
        },
        textStyle: {
          // fontWeight: 'bold'
        }

        // position: ['50%', '0%']
      },
      yAxis: {
        type: 'value',
        // boundaryGap: ['0%', '100%'],
        // min: 'datamin', // 设置 X 轴的最小值为 50
        // max: 'datamax', // 设置 X 轴的最大值为 300
        show: false,
        // max: "dataMax",
        scale: true,
        axisLine: {
          // onZero: false
        }
      },
      visualMap: [
        {
          type: 'piecewise',
          show: false,
          dimension: 1,
          seriesIndex: 1,
          pieces: [
            {
              min: 0.0000001,
              color: 'red'
            },
            {
              max: 0,
              color: 'green'
            }
          ]
        }
      ],
      series: [
        {
          type: 'line',
          smooth: 0,
          symbol: 'none',
          lineStyle: {
            color: 'gray',
            // color: `rgba(${color}, 1)`,
            width: 1
          },
          // markLine: {
          //   symbol: ['none', 'none'],
          //   label: { show: false },
          //   data: [{ xAxis: 1 }, { xAxis: 3 }, { xAxis: 5 }, { xAxis: 7 }]
          // },

          data: data.value.yesterdayarray
        },
        {
          type: 'line',
          smooth: 0,
          symbol: 'none',
          lineStyle: {
            // color: 'rgba(255,0,0,0.5)',
            // color: `rgba(${color}, 1)`,
            width: 1
          },
          // markLine: {
          //   symbol: ['none', 'none'],
          //   label: { show: false },
          //   data: [{ xAxis: 1 }, { xAxis: 3 }, { xAxis: 5 }, { xAxis: 7 }]
          // },
          areaStyle: {
            opacity: 0.2
          },
          data: data.value.array
        }
      ]
    }
    console.log('option', JSON.stringify(option))
    option && myChart.setOption(option)
  }
}
</script>

<style lang="scss" scoped>
#echart-dom {
  width: 120px;
  height: 85px;
  // scale: 0.5;
  position: relative;
  // top: -30px;
  // left: -20px;
}
.trend-chart {
  width: 70px;
  height: 55px;
  // scale: 0.5;
  position: relative;
  // left: -15px;
  // top: -10px;
}
.market-trend-chart {
  width: 70px;
  height: 55px;
  // scale: 0.5;
  position: relative;
  // left: -25px;
}
.category-chart {
  width: 70px;
  height: 35px;
  // scale: 0.5;
  position: relative;
}
.detail-chart {
  width: 60px;
  height: 30px;
  // top: -20px;
  position: relative;
}
.newindex-chart {
  width: 180px;
  height: 70px;
}
.online-chart {
  width: 200px;
  height: 70px;
}
</style>
